import { useRef } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';

const CircleAnim = (props) => {
  const red_circle = useGLTF('/images/Red_Circle.glb');
  const blue_circle = useGLTF('/images/Blue_Circle.glb');

  const redCircleRef = useRef();
  const blueCircleRef = useRef();
  useFrame(() => {
    redCircleRef.current.rotation.z -= 0.05;
    // redCircleRef.current.rotation.x += 0.08;
    blueCircleRef.current.rotation.y += 0.05;
    // blueCircleRef.current.rotation.x -= 0.08;
  });

  const circle_anim = useSpring({
    scale: props.weightState == -2 ? [1, 1, 1] : [0.5, 0.5, 0.5], config: { duration: 1800 },
    onRest: props.setCircleAnim
  });

  return (
    <animated.group scale={circle_anim.scale} position={[0, -props.posZ, -props.posZ]}>
      <primitive ref={redCircleRef} object={red_circle.scene}
        scale={[30, 30, 30]} rotation={[-0.8, 0, 0]} />
      <primitive ref={blueCircleRef} object={blue_circle.scene}
        scale={[30, 30, 30]} rotation={[-0.8, 0, 0]} />
    </animated.group>
  );
}

export default CircleAnim;