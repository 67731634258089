import React, { useState, useEffect, useRef, Suspense } from 'react';
import * as THREE from 'three';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { useSpring, animated } from '@react-spring/three';
import { Billboard, Environment, Html, Image } from '@react-three/drei';
import Floor from '../../assets/floor';
import Table from '../../assets/table';
import Weight from '../../assets/weight';
import Eggs from '../../assets/eggs';
import Base from '../../assets/base';
import BrushGroup from '../../assets/brushgroup';
import WeightPipes from '../../assets/weightpipes';
import Controls from '../../assets/controls';
import Text from '../../assets/text';
import CircleAnim from '../../assets/circleanim';
import Loader from '../../assets/loader';
import './demo.css';
import Pointer from '../../assets/pointer';
import Portrait from '../../assets/portrait';

const Sound = (props) => {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, '/images/egg-cracking.mp3');
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(10);
    sound.current.setLoop(false);
    props.weightState == 1 ? sound.current.play() : sound.current.pause();
    camera.add(listener);
    return () => camera.remove(listener);
  }, [props.weightState]);
  return <positionalAudio ref={sound} args={[listener]} />;
}

const WeightSound = (props) => {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, '/images/weight-sound-2.mp3');
  useEffect(() => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(20);
    sound.current.setLoop(false);
    props.weightState >= 1 && props.weightState <= 10 ? sound.current.play() : sound.current.pause();
    camera.add(listener);
    return () => camera.remove(listener);
  }, [props.weightState]);
  return <positionalAudio ref={sound} args={[listener]} />;
}

function Demo() {
  const [weightState, setWeightState] = useState(-8);
  const [brushActive, setBrushActive] = useState(false);
  const [brushMove, setBrushMove] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [orientation, setOrientation] = useState('');
  console.log('weightState', weightState, brushMove);
  const { innerWidth: width, innerHeight: height } = window;
  const isPortrait = height > width;

  const zoomConst = width >= 1200 ? 30 : width >= 900 ? 45 : 30;
  const fov = width >= 1200 ? 50 : 40;
  const posZ = 6.5;

  const groupPos = useSpring(width >= 900 && width <= 1200 ? { position: weightState <= -7 ? [0, 3, 0] : weightState >= -6 && weightState <= -4 ? [-17, 5, zoomConst - 25] : weightState >= -3 && weightState <= -1 ? [-posZ, 5, zoomConst - 17] : [0, 3, 7], config: { duration: 800 } }
    : { position: weightState >= -6 && weightState <= -4 ? [-17, 5, zoomConst - 20] : weightState >= -3 && weightState <= -1 ? [-posZ, 5, zoomConst - 12] : [0, 3, 0], config: { duration: 800 } });
  const groupRotate = useSpring({ rotation: weightState >= -6 && weightState <= -4 ? [0, -Math.PI / 2, 0] : [0, -Math.PI / 2, 0], config: { duration: 800 } });
  const lightPos = useSpring({ position: weightState >= -6 && weightState <= -1 ? [5, 10, zoomConst] : [0, 20, -30], config: { duration: 800 } });
  const textPos = useSpring({ position: [0, -2.7 + (weightState - 1) + (weightState / 11), -11.5], config: { duration: 200 } });
  const [brushProg, setBrushProg] = useState(0);

  useEffect(() => {
    function handleOrientationChange(event) {
      const { matches, media } = event;
      if (matches) {
        setOrientation(media);
        console.log('orientation', orientation);
      }
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');
    const mediaQueryLandscape = window.matchMedia('(orientation: landscape)');

    if (mediaQueryPortrait.matches) {
      setOrientation('portrait');
    } else if (mediaQueryLandscape.matches) {
      setOrientation('landscape');
    }

    mediaQueryPortrait.addListener(handleOrientationChange);
    mediaQueryLandscape.addListener(handleOrientationChange);

    return () => {
      mediaQueryPortrait.removeListener(handleOrientationChange);
      mediaQueryLandscape.removeListener(handleOrientationChange);
    };
  }, []);

  return (
    <>
      <Portrait />
      {weightState > -7 && <button className='btn_custom reset_btn' onClick={() => setWeightState(-7)}>Restart</button>}
      <Canvas shadows camera={{ position: [0, 0, zoomConst], fov: fov }}>
        <Suspense fallback={<Loader setPageLoaded={() => { setPageLoaded(true); setWeightState(-7) }} />}>
          <animated.group rotation={groupRotate.rotation} position={groupPos.position}>
            <Floor />
            <Eggs posZ={posZ} onPointerEnter={() => setBrushActive(true)}
              onPointerLeave={() => { setBrushActive(false); setBrushMove(false); }}
              onPointerMove={() => { setBrushMove(true); }}
              weightState={weightState} crack={weightState >= 1}
              brushProg={brushProg}
              onClick={() => weightState == -5 ? setWeightState(weightState + 1) : null} />
            <Base posZ={posZ} />
            <Base posZ={-posZ} />
            {(weightState == -8 || weightState == -7) && !isPortrait && <Html position={width > 1200 ? [0, -8, 1.5] : [0, -8, -1.5]}>
              <div className='pointer_msg' style={width > 1200 ? { width: '7.2vw' } : { width: '12vw' }}>These eggs represent your teeth.</div>
            </Html>}
            <CircleAnim posZ={posZ} weightState={weightState}
              setCircleAnim={() => setWeightState(weightState == -2 || weightState == -1 ? weightState + 1 : weightState)} />
            <WeightPipes weightState={weightState} posZ={posZ} />
            <WeightPipes weightState={weightState} posZ={-posZ} />
            <animated.group position={textPos.position}>
              <Text text={weightState + 'X'} position={[0, 0, 0]} active={weightState >= 2} />
            </animated.group>
            {!isPortrait && <Pointer weightState={weightState}
              brushProg={brushProg}
              onClick={() => weightState >= 0 && weightState < 10 ? setWeightState(weightState + 1) : setWeightState(0)} />}
            <Table />
            <Weight weightState={weightState} order={0} posZ={posZ} />
            {[...Array(10)].map((el, i) => {
              return <Weight key={i} weightState={weightState} order={i} posZ={-posZ} />
            })}

            <BrushGroup weightState={weightState} brushActive={brushActive} brushMove={brushMove}
              brushProg={brushProg}
              setBrushProg={() => setBrushProg(brushProg + 1)}
              resetBrushProg={() => setBrushProg(0)}
              onClick={() => setWeightState(weightState == -8 ? weightState + 2 : weightState >= -7 && weightState <= -5 ? weightState + 1 : weightState)}
              onTimeOut={() => setWeightState(weightState == -6 || weightState == -4 ? weightState + 1 : weightState)}
              setCircleAnim={() => setWeightState(weightState == -3 ? weightState + 1 : weightState)} />
            <Sound weightState={weightState} />
            <WeightSound weightState={weightState} />
          </animated.group>
          <Environment files={"/images/Updated.2.hdr"} background blur={1} />
          <Controls weightState={weightState} zoomConst={zoomConst} />
          <animated.pointLight position={lightPos.position} intensity={0.8} castShadow />
        </Suspense>
      </Canvas >
    </>
  );
}

export default Demo;
