import { GradientTexture } from "@react-three/drei";

const Floor = () => {
  return (
    <mesh rotation-x={-Math.PI / 2} position={[0, -30, 0]} rotation={[0, 0, Math.PI / 2]} receiveShadow>
      <circleGeometry args={[100]} />
      <meshStandardMaterial
        opacity={1} transparent roughness={1} >
        <GradientTexture
          stops={[0, 0.5, 1]} // As many stops as you want
          colors={['#909090', '#aaa', '#9f9f9f']} // Colors need to match the number of stops
        // colors={['#5f5f5f', '#777', '#777']} // Colors need to match the number of stops
        />
      </meshStandardMaterial>
    </mesh>
  )
}

export default Floor;