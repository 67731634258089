import { useSpring, animated } from '@react-spring/three';

const WeightPipes = (props) => {
  const pipeDist = 3.2;
  const position = useSpring({ position: props.weightState > -1 ? [0, 0, props.posZ] : [0, -23 / 2, props.posZ] }).position;
  const scale = useSpring({ scale: props.weightState > -1 ? [1, 1, 1] : [1, 0, 1] }).scale;
  return (
    <>
      <animated.group position={position} scale={scale}>
        <mesh position={[0, 0, pipeDist]}>
          <cylinderGeometry args={[0.25, 0.25, 23]} />
          <meshStandardMaterial color="#ddd" metalness={1} roughness={0.1} />
        </mesh>
        {/* <mesh position={[-pipeDist, 0, pipeDist]}>
          <cylinderGeometry args={[0.25, 0.25, 21]} />
          <meshStandardMaterial color="#fff" metalness={0.7} roughness={0.3} />
        </mesh>
        <mesh position={[-pipeDist, 0, -pipeDist]}>
          <cylinderGeometry args={[0.25, 0.25, 21]} />
          <meshStandardMaterial color="#fff" metalness={0.7} roughness={0.3} />
        </mesh> */}
        <mesh position={[0, 0, -pipeDist]}>
          <cylinderGeometry args={[0.25, 0.25, 23]} />
          <meshStandardMaterial color="#ddd" metalness={1} roughness={0.1} />
        </mesh>
      </animated.group>
    </>
  );
}

export default WeightPipes;