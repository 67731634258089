import { useGLTF } from "@react-three/drei";
import { useRef } from "react";
import { useSpring, animated } from '@react-spring/three';

const Toothpaste = (props) => {
  const toothPaste = useGLTF('/images/Pepsodent_Tube.glb');
  const cap = useGLTF('/images/cap_toothpaste.glb');
  // cap_toothpaste.glb
  toothPaste.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });
  cap.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });

  // const tpPos = useSpring({
  //   position: props.weightState == -6 ? [-4.4, -8, -15] : props.weightState == -5 ?
  //     [-6.2, -8, -15] : [6, -8.9, -18.5], config: { duration: 800 },
  //   onRest: props.onTimeOut
  // });

  // const tpRot = useSpring({
  //   rotation: props.weightState == -6 ? [-1.57, 2.18, 0] : props.weightState == -5 ?
  //     [-1.57, 2.3, 0.2] : [0, 1.57, 0], config: { duration: 800 }
  // });
  const tpPos = useSpring({
    position: props.weightState == -6 ? [0.5, -8.4, -22.7] : props.weightState == -5 ?
      [0.5, -8.3, -24.7] : [0, -8.9, -14], config: { duration: 800 },
    onRest: props.onTimeOut
  });

  const tpRot = useSpring({
    rotation: props.weightState == -6 ? [0.6, 0, -1.5] : props.weightState == -5 ?
      [0.8, 0, -1.5] : [0, 0, -1.5], config: { duration: 800 }
  });

  // const capPos = useSpring({
  //   position: props.weightState == -6 || props.weightState == -5 ?
  //     [7.4, -9.9, -17] : [7.4, -8.85, -18.5], config: { duration: 800 },
  //   onRest: props.onTimeOut
  // });
  // const capRot = useSpring({
  //   rotation: props.weightState == -6 || props.weightState == -5 ?
  //     [-1.57, 0, 0] : [0, 1.57, 0], config: { duration: 800 }
  // });

  const capPos = useSpring({
    position: props.weightState == -6 || props.weightState == -5 ?
      [0, -9.9, -12.6] : [0, -8.9, -12.6], config: { duration: 800 },
    onRest: props.onTimeOut
  });
  const capRot = useSpring({
    rotation: props.weightState == -6 || props.weightState == -5 ?
      [-1.57, 0, 0] : [0, 0, 0], config: { duration: 800 }
  });

  return (<>
    <animated.primitive object={toothPaste.scene}
      onClick={props.onClick}
      scale={[0.06, 0.06, 0.06]}
      position={tpPos.position}
      rotation={tpRot.rotation}
    />
    <animated.primitive object={cap.scene}
      onClick={props.onClick}
      scale={[0.05, 0.05, 0.05]}
      position={capPos.position}
      rotation={capRot.rotation}
    />
  </>);
}

export default Toothpaste;