import { useGLTF } from "@react-three/drei";

const Table = (props) => {
  const gltf = useGLTF('/images/Main_Table.glb');
  gltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; node.receiveShadow = true; }
  });
  return (<primitive object={gltf.scene} position={[-5, -30, 10]} scale={[0.27, 0.25, 0.35]} receiveShadow />);
}

export default Table;