import { Html } from '@react-three/drei';
import { useNavigate } from 'react-router-dom';

const Pointer = (props) => {
  const { innerWidth: width, innerHeight: height } = window;
  const navigate = useNavigate();

  return (
    <>
      {width >= 600 ?
        <Html position={props.weightState <= -7 ? [0, -6.5, -18] :
          props.weightState == -5 ? [0, -3.2, -6] : props.weightState == -3 ? [0, -6, -3.5] :
            width >= 600 && width <= 900 ? [0, 8, 22] : width > 900 && width <= 1200 ? [0, 4, 18] : [0, 4, 20]}>
          <div style={{
            display: props.weightState <= -9 || props.weightState == -6 || props.weightState == -4 ||
              props.weightState == -2 || props.weightState == -1 ? 'none' : 'block'
          }}>
            {/* <div className='pointer_main'>
              <div className='pointer_inner'>
              </div>
            </div> */}
            <img src='/images/cursor_pointer.svg' className='cursor_pointer'
              style={props.weightState == -3 ? { transform: 'rotateZ(310deg)' } : props.weightState >= 0 ? { display: 'none' } : {}} />
            <div className='pointer_msg'
              style={props.weightState == -3 ? { left: width <= 1200 ? '-18vw' : '-11vw' } : props.weightState >= 0 ? (width >= 600 && width <= 900 ? { top: '5.5vw', bottom: 'unset', left: '-5.5vw' } : width >= 900 && width <= 1200 ? { top: '3vw', bottom: 'unset', left: '-5vw', width: '18vw' } : { top: '3vw', bottom: 'unset', left: '-5vw', width: '14vw' }) : {}}>
              {props.weightState <= -7 && 'Click on the tube to apply Pepsodent on the Toothbrush.'}
              {props.weightState == -5 && 'Tap on this egg to apply the paste.'}
              {props.weightState == -3 && <>Brush on the egg to apply the paste. <div className='brush_progress'>
                <div style={{ width: props.brushProg / 2 + '%' }}></div>
              </div></>}
              {props.weightState >= 0 && <>Let's test the strength of the eggs by dropping weights on them.
                <button className='btn_custom btn_weight' onClick={props.weightState == 10 ? () => navigate('/conclusion') : props.onClick}>{props.weightState == 10 ? 'Next' : 'Drop'}</button>
                <div className='brush_progress weight_prog'>
                  <div style={{ width: props.weightState * 10 + '%' }}></div>
                </div></>}
            </div>
          </div>
        </Html> : null}
    </>
  );
}

export default Pointer;