import { Html } from "@react-three/drei";

const ResText = (props) => {

  return (
    <>
      <Html position={[0, props.position[1] - 0.5, 1.5]}>
        <div className='pointer_main pointer_main_weight' style={{ display: props.active ? 'flex' : 'none' }}>
          <div className='pointer_inner pointer_inner_weight'>
          </div>
        </div>
      </Html>

      <Html position={props.position} rotation={[0, Math.PI / 2, 0]} >
        <div className="pointer_weight_text" style={{ display: props.active ? 'flex' : 'none' }}>
          {props.text}
        </div>
      </Html>

    </>
  )
}

export default ResText;