import { useGLTF } from "@react-three/drei";
import { useRef } from "react";
import { useSpring, animated } from '@react-spring/three';

const Paste = (props) => {
  const pasteRef = useRef();
  const gltf = useGLTF('/images/Paste.glb');
  gltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });

  const pasteScale = useSpring({
    scale: props.weightState >= -5 && props.weightState <= -3 ?
      [500, 400, 550] : [0, 0, 0], config: { duration: 800 }
  });
  const pastePos = useSpring({
    postion: props.weightState >= -5 && props.weightState <= -3 ?
      [0, 0.7, -0.75] : [0, 0.7, -0.75], config: { duration: 800 }
  });

  return (<animated.primitive ref={pasteRef} object={gltf.scene}
    // position={[10, -9.4, -9.4]}
    position={pastePos.postion}
    scale={pasteScale.scale}
    rotation={[0, Math.PI, 0]}
  />);
}

export default Paste;