import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import CustomWebcam from './components/screens/photo/photo';
import Demo from './components/screens/demo/demo';
import SplashScreen from './components/screens/splashscreen/splashscreen';
import SplashScreenCoat from './components/screens/splashscreencoat/splashscreencoat';
import Form from './components/screens/form/form';
import Conclusion from './components/screens/conclusion/conclusion';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/splashtest" element={<SplashScreen />} />
        <Route path="/" element={<SplashScreenCoat />} />
        <Route path="/form" element={<Form />} />
        <Route path="/photo" element={<CustomWebcam />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/conclusion" element={<Conclusion />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
