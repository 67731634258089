import { useState } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { useGLTF } from '@react-three/drei';

const Eggs = (props) => {
  const [active1, setActive1] = useState(true);

  const egg1 = useGLTF('/images/egg.glb');
  const egg2 = useGLTF('/images/egg_copy.glb');
  const egg_crack = useGLTF('/images/Egg_crack.glb');
  const egg_pepsodent = useGLTF('/images/Egg_Pepsodent.glb');
  const egg_foam = useGLTF('/images/Egg_foam_3.glb');
  egg1.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });
  egg2.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });
  egg_pepsodent.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; }
  });

  const scale_crack = useSpring({ scale: props.crack ? [0.6, 0.6, 0.6] : [0.2, 0.2, 0.2] });

  return (
    <>
      <animated.primitive object={egg1.scene} position={[0, -7, props.posZ]} scale={[0.5, 0.5, 0.5]}
        onClick={() => setActive1(!active1)} />
      <animated.primitive object={egg_crack.scene} position={[0, -9.1, props.posZ]} scale={scale_crack.scale} />
      <animated.primitive object={props.weightState >= -2 ? egg_pepsodent.scene : egg2.scene} position={[0, -7, -props.posZ]}
        scale={[0.5, 0.5, 0.5]} rotation={[0, 1.45, 0]}
        onClick={props.onClick} onPointerEnter={props.onPointerEnter} onPointerLeave={props.onPointerLeave}
        onPointerMove={props.onPointerMove} />
      <animated.primitive object={egg_foam.scene} position={[props.weightState == -3 ? props.brushProg * (0.3 / 200) : 0, -6.8, -props.posZ]}
        scale={[0.55, 0.55, 0.55]}
        rotation={[0, 1.57, 0]} />
      {/* <animated.primitive object={paste.scene} position={[1.7, -5, -props.posZ + 0.6]}
       scale={[1200, 200, 700]}
      //  scale={[500, 400, 550]}
        rotation={[0, 0, -1.57]} /> */}
    </>
  );
}

export default Eggs;