import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { useThree, useFrame, useLoader } from '@react-three/fiber';
import Brush from './brush';
import Toothpaste from './toothpaste';
import Paste from './paste';

const Sound = (props) => {
  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new THREE.AudioListener());
  const buffer = useLoader(THREE.AudioLoader, '/images/brush-sound-1.mp3');
  useEffect(() => {
    console.log('brush', props.brushActive, props.brushMove, props.weightState === -3,
      props.brushActive && props.brushMove && props.weightState === -3)
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(2);
    sound.current.setLoop(true);
    props.brushActive && props.brushMove && props.weightState === -3 ? sound.current.play() : sound.current.pause();
    camera.add(listener);
    return () => camera.remove(listener);
  }, [props.brushActive, props.brushMove, props.weightState]);
  return <positionalAudio ref={sound} args={[listener]} />;
}

const BrushGroup = (props) => {
  const brushRef = useRef();
  const { viewport } = useThree();

  // let brushTime = 0;
  useFrame(({ mouse }) => {
    if (props.brushActive && props.weightState === -3) {
      const x = ((mouse.x * viewport.width) / 9) + 6;
      const y = (mouse.y * viewport.height / 7.5) - 5;
      brushRef.current.position.set(3, y, -x);
      brushRef.current.rotation.set(0, 0, Math.PI / 2);
      // brushTime += 1;
      // console.log('brushTime', brushTime);
      props.setBrushProg();
      if (props.brushProg >= 200) {
        props.setCircleAnim();
      }
    } else {
      props.resetBrushProg();
      // brushRef.current.position.set(-4, -10.1, -15);
      brushRef.current.position.set(2, -10.1, -22);
      // brushRef.current.rotation.set(0, -Math.PI / 2, 0);
      brushRef.current.rotation.set(0, Math.PI, 0);
    }
  })

  return (
    <>
      <group ref={brushRef}>
        <Sound brushActive={props.brushActive} brushMove={props.brushMove} weightState={props.weightState} />
        <Brush onClick={props.onClick} />
        <Paste weightState={props.weightState} />
      </group>
      <Toothpaste weightState={props.weightState} onClick={props.onClick} onTimeOut={props.onTimeOut} />
    </>
  )
}

export default BrushGroup;