import { useNavigate } from "react-router-dom";
import './form.css';
import { useState } from "react";

const Form = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('male');

  const onOptionChange = e => {
    setType(e.target.value)
  }

  // const [inputs, setInputs] = useState({});

  const onNextButton = () => {
    navigate('/photo', { state: { type: type } })
  }

  return (
    <div className="form_custom">
      <div className="form_title">Please enter your details</div>
      <div className="form_main">
        <label htmlFor="fc_name">Name <span>*</span></label>
        <input id="fc_name" name="fc_name" type="text" />
        <label htmlFor="fc_email">Email <span>*</span></label>
        <input id="fc_email" name="fc_email" type="email" />
        <label htmlFor="fc_age">Age <span>*</span></label>
        <input id="fc_age" name="fc_age" type="number" min={12} />
        <div><label>Gender <span>*</span></label></div>
        <input id="fc_male" name="fc_gender" type="radio" value="male" defaultChecked onChange={onOptionChange} />
        <label htmlFor="fc_male" className="radio_label">Male</label>
        <input id="fc_female" name="fc_gender" type="radio" value="female" onChange={onOptionChange} />
        <label htmlFor="fc_female" className="radio_label">Female</label>
        <button className="btn_custom" onClick={onNextButton}>Next</button>
      </div>
      <img src="/images/Pepsodent_vector.svg" className="form_vector" />
    </div>
  );
};

export default Form;