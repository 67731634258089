const Portrait = () => {
  return (
    <div className="portrait_instruction">
      <img src={'/images/portrait_rotate.png'} />
      <div>Rotate screen to
        start</div>
    </div>
  );
};

export default Portrait;