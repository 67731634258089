import { useGLTF } from "@react-three/drei";

const Brush = (props) => {
  const gltf = useGLTF('/images/Brush_new.glb');
  gltf.scene.traverse(function (node) {
    if (node.isMesh) { node.castShadow = true; node.receiveShadow = true; }
  });
  return (
    <primitive object={gltf.scene}
      scale={[80, 80, 80]}
      onClick={props.onClick}
    />
  )
}
export default Brush;