import { useNavigate } from "react-router-dom";
import './conclusion.css';

const Conclusion = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const navigate = useNavigate();

  return (
    // <>
    //   <button className='btn_custom reset_btn' onClick={() => navigate('/demo')}>Restart</button>
    //   <img src="/images/conclusion_v2.jpg" style={{ width: '100vw' }} />
    // </>
    <div className="splash_screen">
      <img src="/images/Pepsodent_logo.svg" className="splash_logo" />
      <div className="splash_title_cont">
        <div className="splash_title">10X</div>
        <div className="splash_subtitle">STRONGER<br />TEETH</div>
      </div>
      <div className="splash_img_cont">
        {/* <img src="/images/splash_eggs.png" className="splash_eggs" /> */}
        <img src="/images/splash_screen_asset.png" className="splash_paste" />
      </div>
      <button className='btn_custom reset_btn' onClick={() => navigate('/demo')}>Restart</button>
    </div>
  );
};
// Splash-Desktop
export default Conclusion;