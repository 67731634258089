import { useSpring, animated } from '@react-spring/three';
import { useTexture } from "@react-three/drei";

const Weight = (props) => {
  const positionY = ((props.order >= props.weightState ? 12 : -1 * (3.5 / 1.2)) * 1.2) + (props.order * 1);
  const scale = useSpring({ scale: props.weightState > -1 ? [1, 1, 1] : [0, 0, 0] }).scale;
  const position = useSpring({ position: [0, positionY, props.posZ] }).position;
  const texture = useTexture("/images/metal_texture-2.jpg");
  return (
    <>
      <animated.mesh position={position} scale={scale}>
        <cylinderGeometry attach="geometry" args={[4, 4, 0.8]} />
        <meshStandardMaterial attach="material" color="#ddd" metalness={1} roughness={0.1} map={texture} />
      </animated.mesh>
    </>
  );
}

export default Weight;